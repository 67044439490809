import React, { useState, useEffect } from "react";
import { getArticles, getArticleDetails } from "../../helpers/articles";
import { itemsPerPage } from "../../constants";
import { getLastYearArticles } from "../../helpers/statistics";
import { getInstitutionsPercentage } from "../../helpers/institutions";
import { MDBCol, MDBRow } from 'mdbreact';
import { userSettings } from "../../user-settings";
import {
    BarChart,
    ArticleDetailsModal,
    Card,
    CustomTable,
    PieChart
} from "pubtrack-frontend-library";


const Articles = () => {
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [articleDetails, setArticleDetails] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [articles, setArticles] = useState([]);
    const [institutionsPercentage, setInstitutionsPercentage] = useState([]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'manuscript ID',
                accessor: 'manuscript_id',
            },
            {
                Header: 'DOI',
                accessor: 'doi',
            },
            {
                Header: 'journal title',
                accessor: 'journal',
            },
            {
                Header: 'manuscript title',
                accessor: 'manuscript_title'
            },
            {
                Header: 'last event',
                accessor: 'lastevent',
            }
        ], []
    );

    useEffect(() => {
        getLastYearArticles()
            .then(res => setArticles(res.data));

        getInstitutionsPercentage()
            .then(res => setInstitutionsPercentage(res.data));
    }, []);

    useEffect(() => {
        if (selectedMessage) {
            getArticleDetails(selectedMessage)
                .then(res => setArticleDetails(res.data))
                .then(setIsOpen(true))
                .catch(error => console.log(error))
                .then(setSelectedMessage(null))
        }
    }, [selectedMessage]);

    const getArticlesData = ({ searchValue, offset, itemsPerPage, sortBy, sortDir }) => {
        return getArticles(searchValue, offset, itemsPerPage, sortBy, sortDir, "published")
            .then(res => ({ data: res.data.articles, total: res.data.total }))
    };

    const onSelectRow = row => {
        const messageId = row && row.original ? row.original["id"] : null;
        setSelectedMessage(messageId);
    };


    return (
        <div className="container">
            <MDBRow className="mt-5">
                <MDBCol md='8' className="mb-5 mt-4">
                    <Card
                        title="New articles per month:"
                        content={<BarChart data={articles} xAxisKey="month" yAxisKey="count" />}
                    />
                </MDBCol>
                <MDBCol md='4' className="mb-5 mt-4">
                    <Card
                        title="With/without ROR institutions percentage:"
                        content={<PieChart data={institutionsPercentage} labelKey="label" valueKey="count" />}
                    />
                </MDBCol>
            </MDBRow>

            <div className="mb-5">
                <CustomTable
                    columns={columns}
                    itemsPerPage={itemsPerPage}
                    getTableData={getArticlesData}
                    onSelectRow={onSelectRow}
                />
            </div>

            <ArticleDetailsModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                articleDetails={articleDetails}
                tabItems={userSettings.details}
            />
        </div>
    );
};


export default Articles;