import React from "react";
import { MDBNavbar, MDBNavbarBrand } from "mdbreact";
import logo from "../images/logo.svg";
import styled, { withTheme } from 'styled-components';

const NavbarWrapper = styled.div`
  .navbar-color {
    background-color: ${props => props.theme.navbarLogoBackgroundColor};
  }

  .navbar {
    box-shadow: none !important;
  }

  .logotype-navbar {
    background-color: ${props => props.theme.navbarLogoBackgroundColor};
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }

  .logotype-divider {
    font-weight: bold;
    font-size: 1.4rem;
    color: #fff;
    padding: 0 18px;
  }

  .logotype-image {
    max-height: 1.8rem;
  }
`

const Navbar = () => {

  return (
    <NavbarWrapper>
      <MDBNavbar color="navbar-color px-5" dark expand="md">
        <MDBNavbarBrand>
          <div className="logotype-navbar">
            <div className="logotype-title">Publication Tracker</div>
            <div className="logotype-divider"> | </div>
            <img src={logo} alt="logotype" className="logotype-image"></img>
          </div>
        </MDBNavbarBrand>
      </MDBNavbar>
    </NavbarWrapper>
  );
};


export default withTheme(Navbar);