import React from "react";
import Routes from "./routes";
import "./App.css";
import { ThemeProvider } from "styled-components";
import { theme, GlobalStyles, Footer } from "pubtrack-frontend-library";

const customTheme = {
  ...theme,

  primaryColor: '#1b75bc',
  secondaryColor: '#0b0b0b',

  accordionHeaderBackgroundColor: '#1b75bc',
  modalHeaderBackgroundColor: '#1b75bc',
  tabItemColor: '#fff',

  navbarLogoBackgroundColor: '#0b0b0b',
  navbarBackgroundColor: '#0b0b0b',
  navbarButtonHoverColor: '#fff',
  navbarButtonTextColor: '#fff',
  navbarButtonHoverTextColor: "#1b75bc",
  navbarTextTransform: 'uppercase',
  logoutButtonBackgroundColor: '#1b75bc',
  logoutButtonTextColor: "#fff",
  navbarButtonActiveTextColor: "#1b75bc",

  paginationButtonBackgroundColor: '#1b75bc',
  paginationButtonHoverBackgroundColor: '#0f2834',

  tableHeaderBackgroundColor: '#1b75bc',
  searchButtonBackgroundColor: '#1b75bc',

  defaultButtonBackgroundColor: '#1b75bc',
  primaryButtonBackgroundColor: '#1b75bc',
  secondaryButtonBackgroundColor: '#1b75bc',
  colorsPalette: [
    "#002242", "#144773", "#346ea4", "#4e8ab8", "#6faed2", "#9ecbe3"
  ]
}

const App = () => (
  <ThemeProvider theme={customTheme}>
    <GlobalStyles />
    <Routes />
    <Footer />
  </ThemeProvider>
);

export default App;